<template>
  <div class="row form-data">
    <logo-upload @refresh="getEdit()" :form="form" :logo="form.logo" />
    <b-form :form="form" @submit.prevent="handleSubmit">
      <div class="row">
        <div class="col-md-4">
          <b-form-group label="CNPJ" class="input-loading">
            <b-input
              disabled
              v-model="form.cnpj"
              :class="invalid_cnpj && 'is-invalid'"
              @change="getCnpj()"
              v-mask="cnpjNumberMask"
              placeholder="CNPJ"
            />
            <b-icon icon="disc" v-if="loadingCnpj" />
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Razão Social">
            <b-form-input
              disabled
              :class="$v.form.social_reason.$error && 'is-invalid'"
              v-model="form.social_reason"
              placeholder="Razão Social"
            />
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Nome Fantasia">
            <b-form-input
              disabled
              v-model="form.fantasy_name"
              placeholder="Nome Fantasia"
            />
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Atividade Principal">
            <b-form-input
              :class="$v.form.segment.$error && 'is-invalid'"
              v-model="form.segment"
              placeholder="Atividade Principal"
            />
          </b-form-group>
        </div>
        <div class="row px-3">
          <div class="col-md-4">
            <b-form-group label="CEP" class="input-loading">
              <b-form-input
                :class="$v.form.zip_code.$error && 'is-invalid'"
                v-model="form.zip_code"
                @keyup="getZipCode"
                v-mask="'#####-###'"
                placeholder="CEP"
              />
              <b-form-invalid-feedback v-if="$v.form.zip_code.$error">
                Campo obrigatório
              </b-form-invalid-feedback>
              <b-icon icon="disc" v-if="loading_zip_code" />
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Cidade">
              <b-form-input
                v-model="$v.form.city.$model"
                :class="$v.form.city.$error && 'is-invalid'"
                placeholder="Cidade"
              />
              <b-form-invalid-feedback v-if="$v.form.city.$error">
                Campo obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="UF">
              <b-form-input
                v-model="form.state"
                :class="$v.form.state.$error && 'is-invalid'"
                placeholder="UF"
              />
              <b-form-invalid-feedback v-if="$v.form.state.$error">
                Campo obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Logradouro">
              <b-form-input
                v-model="form.place"
                :class="$v.form.place.$error && 'is-invalid'"
                placeholder="Logradouro"
              />
            </b-form-group>
            <b-form-invalid-feedback v-if="$v.form.place.$error">
              Campo obrigatório
            </b-form-invalid-feedback>
          </div>
          <div class="col-md-2">
            <b-form-group label="Número">
              <b-form-input
                v-model="form.number"
                :class="$v.form.number.$error && 'is-invalid'"
                placeholder="Número"
              />
              <b-form-invalid-feedback v-if="$v.form.number.$error">
                Campo obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group label="Complemento">
              <b-form-input
                v-model="form.complement"
                placeholder="Complemento"
              />
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="Bairro">
              <b-form-input
                v-model="form.neighborhood"
                :class="$v.form.neighborhood.$error && 'is-invalid'"
                placeholder="Bairro"
              />
            </b-form-group>
            <b-form-invalid-feedback v-if="$v.form.neighborhood.$error">
              Campo obrigatório
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="col-md-3">
          <b-form-group label="Contato">
            <b-form-input
              v-model="form.phone"
              :class="$v.form.phone.$error && 'is-invalid'"
              placeholder="Contato"
            />
            <b-form-invalid-feedback v-if="$v.form.phone.$error">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group label="Email">
            <b-form-input
              v-model="$v.form.email.$model"
              :class="$v.form.email.$error && 'is-invalid'"
              placeholder="empresa@email.com"
            />
            <b-form-invalid-feedback
              v-if="!$v.form.email.email || !$v.form.email.required"
            >
              {{ $t("auth.type-valid-email") }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>
      <div class="mt-4 d-flex w-100 justify-content-between">
        <b-button
          size="sm"
          @click="$emit('submited')"
          type="reset"
          variant="light"
          class="mr-2"
        >
          Limpar campos
        </b-button>
        <b-button
          size="sm"
          :disabled="isDisabled() || loadingSubmit"
          type="submit"
          variant="primary"
        >
          <b-spinner v-if="loadingSubmit" small class="mr-2" />
          <span>Salvar</span>
        </b-button>
      </div>
    </b-form>
  </div>
</template>
<script>
import { api } from "@/services/api";
import { mapActions, mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { getCnpj } from "@/utils/validations/getCnpj";
import LogoUpload from "./logoUpload";

export default {
  mixins: [validationMixin],
  directives: { mask },
  components: {
    LogoUpload,
  },

  data() {
    return {
      role: "",
      disableCheck: false,
      invalid_cnpj: false,
      valid_cnpj: false,
      cnpjNumberMask: "",
      form: {
        logo: "",
        social_reason: null,
        fantasy_name: null,
        segment: null,
        zip_code: null,
        city: null,
        state: null,
        place: null,
        number: null,
        complement: null,
        neighborhood: null,
        phone: null,
      },
      entrerpise_data: null,
      email: null,
      loadingSubmit: false,
      loadingCnpj: false,
      loading_zip_code: false,
    };
  },
  validations: {
    form: {
      cnpj: { required },
      social_reason: { required },
      segment: { required },
      zip_code: { required },
      city: { required },
      state: { required },
      place: { required },
      number: { required },
      neighborhood: { required },
      phone: { required },
      email: {
        required,
        email: email,
      },
    },
  },
  computed: {
    ...mapGetters(["user", "is_preview", "preview_personify"]),
  },
  methods: {
    ...mapActions([
      "show_toast",
      "get_user",
      "edit_legal_personnel",
      "get_legal_personnel",
    ]),
    isDisabled() {
      if (this.invalid_cnpj || !this.form.cnpj) {
        return true;
      }
      return false;
    },
    isDisabledInput(element) {
      return !(this.valid_cnpj && !element);
    },

    getCnpj() {
      this.resetForm();
      this.loadingCnpj = true;
      const cnpj = this.form.cnpj.replace(/[^\d]/g, "");
      getCnpj(cnpj)
        .then((data) => {
          if (data.status !== "ERROR") {
            this.valid_cnpj = true;
            this.invalid_cnpj = false;
            this.entrerpise_data = data;
            this.form = {
              ...this.form,
              cnpj: this.entrerpise_data.cnpj,
              social_reason: this.entrerpise_data.nome,
              fantasy_name: this.entrerpise_data.fantasia,
              segment: this.entrerpise_data.atividade_principal[0].text,
              zip_code: this.entrerpise_data.cep,
              city: this.entrerpise_data.municipio,
              state: this.entrerpise_data.uf,
              place: this.entrerpise_data.logradouro,
              number: this.entrerpise_data.numero,
              complement: this.entrerpise_data.complemento,
              neighborhood: this.entrerpise_data.bairro,
              email: this.entrerpise_data.email,
              phone: this.entrerpise_data.telefone,
            };
          } else {
            this.valid_cnpj = false;
            this.invalid_cnpj = true;
            this.resetForm();
          }
          this.loadingCnpj = false;
        })
        .catch(() => {
          this.loadingCnpj = false;
          this.invalid_cnpj = true;
          this.resetForm();
        });
    },
    getZipCode() {
      let zipCode = this.form?.zip_code;
      if (zipCode) {
        zipCode = zipCode.replace(/[^\d]/g, "");
        if (zipCode.length === 8) {
          this.loading_zip_code = true;
          api
            .get(`/homologation/get-zip-code/${zipCode}/`)
            .then(({ data }) => {
              if (data.erro) {
                this.invalid_cep = true;
              } else {
                this.invalid_cep = false;
              }
              this.responsible_data = data;
              this.form = {
                ...this.form,
                city: this.responsible_data.localidade,
                state: this.responsible_data.uf,
                place: this.responsible_data.logradouro,
                neighborhood: this.responsible_data.bairro,
              };
              this.loading_zip_code = false;
              this.$emit("submited");
            })
            .catch(() => {
              this.loading_zip_code = false;
            });
        }
      }
    },

    async saveOrUpdate(payload) {
      return await this.edit_legal_personnel(payload);
    },

    handleSubmit() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.loadingSubmit = true;

        const payload = this.getFormDataObject();
        delete payload.logo;

        this.saveOrUpdate(payload).then(({ status }) => {
          this.loadingSubmit = false;
          if ([200, 201].includes(status)) {
            this.$emit("submited");
            this.get_user({ id: this.user.id });
            this.show_toast({
              message: "Salvo com sucesso!",
              type: "success",
            });
            return;
          }
          this.show_toast({
            message:
              "Há algum problema com dados cadastrados, verifique e tente novamente!",
            type: "error",
          });
        });
      }
    },

    getFormDataObject() {
      const objectForm = {
        matrix: this.user.enterprise.id,
        id: this.user.enterprise.id,
        social_reason: this.form.social_reason,
        fantasy_name: this.form.fantasy_name,
        segment: this.form.segment,
        email: this.form.email,
        logo: this.form.logo?.id,
        phone: this.form.phone,
        cnpj: this.form.cnpj?.replace(/[^\d]/g, ""),
        address: {
          place: this.form.place,
          number: this.form.number,
          complement: this.form.complement,
          zip_code: this.form?.zip_code?.replace(/[^\d]/g, ""),
          neighborhood: this.form.neighborhood,
          city: this.form.city,
          state: this.form.state,
          country: "Brasil",
        },
      };
      if (!objectForm.id) {
        delete objectForm.id;
      }
      return objectForm;
    },

    resetForm() {
      this.form = {
        cnpj: this.form.cnpj,
        social_reason: null,
        fantasy_name: null,
        segment: null,
        zip_code: null,
        city: null,
        state: null,
        place: null,
        number: null,
        complement: null,
        neighborhood: null,
        phone: null,
      };
    },

    getEdit() {
      this.get_legal_personnel({ id: this.user.enterprise?.id }).then(
        ({ data }) => {
          if (data) {
            this.form = data;

            if (!data?.address) {
              data = { ...data, address: {} };
            }

            const {
              zip_code,
              city,
              state,
              neighborhood,
              number,
              complement,
              place,
            } = data.address;
            this.form.logo = data.logo;
            this.form.zip_code = zip_code || "";
            this.form.city = city || " ";
            this.form.state = state || "";
            this.form.complement = complement || "";
            this.form.neighborhood = neighborhood || "";
            this.form.number = number || "";
            this.form.place = place || "";
            this.$nextTick(() => {
              this.cnpjNumberMask = "##.###.###/####-##";
            });
          }
        }
      );
    },
  },
  mounted() {
    this.getEdit();
  },
};
</script>
