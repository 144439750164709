<template>
  <div class="image-logo mx-auto mb-5 mt-2">
    <div class="w-100">
      <div
        class="logo-uploaded text-center bg-light mb-4 p-2 actions"
        v-if="url"
      >
        <img :src="url" alt="" height="40" class="d-block logo-preview" />
        <b-icon-trash-fill
          v-disable="removing"
          style="width: 120px"
          class="pointer icon-action mx-auto mt-2 text-center p-2"
          @click="handleRemoveLogo()"
        />
        <span v-if="removing">Apagando...</span>
      </div>
      <file-upload
        v-else
        style="border: 1px dashed gray"
        ref="upload"
        @upload="handleUploadAttachment"
        disableRotation
        autoUpload
        enableZoom
        label="Arraste e solte seu logo aqui"
        :width="300"
        container="100%"
        :height="80"
      />
    </div>
  </div>
</template>
<script>
import FileUpload from "@/components/FileUpload";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  components: {
    FileUpload,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data() {
    return {
      url: "",
      removing: false,
    };
  },
  methods: {
    ...mapActions(["edit_provider", "edit_borrower"]),
    async handleUploadAttachment(files) {
      const file = files[0];
      this.url = file.file;

      const role = this.user.role;
      this[`edit_${role}`]({ id: this.form.id, logo: file.id }).then(() => {
        this.$emit("refresh");
      });
    },
    handleRemoveLogo() {
      this.removing = true;
      const role = this.user.role;
      this[`edit_${role}`]({ id: this.form.id, logo: null }).then(() => {
        this.$emit("refresh");
        this.removing = false;
        this.url = null;
      });
    },
  },
  watch: {
    form: {
      handler(form) {
        this.url = form?.logo?.file ? form.logo.file : null;
      },
      immediate: true,
    },
  },
};
</script>


<style lang="scss">
.logo-preview {
  height: 100px;
  width: 100%;
  object-fit: contain;
}
</style>